$primary-color: "#0D1C36";

// kendo custom
$primary: #0d1c36;
@import "~@progress/kendo-theme-default/dist/all.scss";
// end kendo custom

html,
body {
    height: 100%;
}
body {
    margin: 0;
    overflow: hidden;
}
a:focus,
button:focus,
input:focus,
textarea:focus {
    outline: none;
}

button {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.absolute-style {
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

@mixin margin-top-mixin($top: 10px) {
    margin-top: $top;
}

.margin-top-15 {
    @include margin-top-mixin(15px);
}

.margin-top-2 {
    @include margin-top-mixin(2px);
}

@mixin flex-center-mixin($justify: center) {
    display: flex;
    justify-content: $justify;
    align-items: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
}
.flex-col {
    display: flex;
    flex-direction: column;
}
.flex-center {
    @include flex-center-mixin();
}
.flex-center-justify-between {
    @include flex-center-mixin(space-between);
}
.flex-center-justify-start {
    @include flex-center-mixin(flex-start);
}
.flex-center-justify-end {
    @include flex-center-mixin(flex-end);
}

.text-bold-18px {
    font-size: 18px;
    font-weight: 800;
    color: #0d1c36;
}

/** Scrollable **/
$bg-color-scroll: #aab2bf;
$bg-color-scroll-2: #d9d9d9;
$bg-color-scoll-track: #d7d7d7;
$border-radius-scroll: 4px;
$width-scroll: 6px;
$height-scroll: 6px;

$bg-blue-scroll: #a6c2f9;

@mixin scroll-custom {
    ::-webkit-scrollbar {
        width: $width-scroll;
        height: $height-scroll;
    }
    ::-webkit-scrollbar-thumb {
        background: $bg-color-scroll;
        border-radius: $border-radius-scroll;
    }
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 $border-radius-scroll $bg-color-scroll-2;
        border-radius: $border-radius-scroll;
        background: $bg-color-scoll-track;
    }
    scrollbar-color: $bg-color-scroll $bg-color-scroll-2;
    scrollbar-width: thin;
}

@mixin scroll-custom-color($custom-color) {
    ::-webkit-scrollbar {
        width: $width-scroll;
        height: $height-scroll;
    }
    ::-webkit-scrollbar-thumb {
        background: $custom-color;
        border-radius: $border-radius-scroll;
    }
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 $border-radius-scroll $bg-color-scroll-2;
        border-radius: $border-radius-scroll;
        background: $bg-color-scoll-track;
    }
    scrollbar-color: $custom-color $bg-color-scroll-2;
    scrollbar-width: thin;
}

@mixin scroll-custom-width($width) {
    ::-webkit-scrollbar {
        width: $width;
        height: $width;
    }
    ::-webkit-scrollbar-thumb {
        background: $bg-color-scroll;
        border-radius: $border-radius-scroll;
    }
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 $border-radius-scroll $bg-color-scroll-2;
        border-radius: $border-radius-scroll;
        background: $bg-color-scoll-track;
    }
    scrollbar-color: $bg-color-scroll $bg-color-scroll-2;
    scrollbar-width: thin;
}

.custom-scrollbar {
    @include scroll-custom();
}

.custom-scrollbar-color {
    @include scroll-custom-color($bg-blue-scroll);
}

.custom-scrollbar-grid {
    @include scroll-custom-width(8px);
}
.custom-scrollbar-handle {
    @include scroll-custom();
    overflow: hidden !important;
    &:hover {
        overflow: auto !important;
        overflow-y: auto !important;
    }
}
.custom-scrollbar-table {
    & .ant-table-body::-webkit-scrollbar-thumb {
        background: $bg-color-scroll;
        border-radius: $border-radius-scroll;
    }
    & .ant-table-body::-webkit-scrollbar {
        width: $width-scroll;
        height: $height-scroll;
    }
    & .ant-table-body::-webkit-scrollbar-track {
        box-shadow: inset 0 0 $border-radius-scroll $bg-color-scroll-2;
        border-radius: $border-radius-scroll;
        background: $bg-color-scoll-track;
    }
}
.custom-scrollbar-table-key {
    & .ant-table-body::-webkit-scrollbar-thumb {
        background: $bg-color-scroll;
        border-radius: $border-radius-scroll;
    }
    & .ant-table-body::-webkit-scrollbar {
        height: $height-scroll;
        width: $width-scroll;
    }
    & .ant-table-body::-webkit-scrollbar-track {
        box-shadow: inset 0 0 $border-radius-scroll $bg-color-scroll-2;
        border-radius: $border-radius-scroll;
        background: $bg-color-scoll-track;
    }
}
/** end scroll **/

/** tabs custom **/
@mixin tabs-custom-by-number-tab($numberTab: 3) {
    height: 100%;
    & > .ant-tabs-nav {
        padding: 0 8px;
        & > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
            width: 99%;
            & > .ant-tabs-tab {
                flex-basis: 0;
                flex-grow: 1;
                justify-content: center;
                margin-right: 0;
                color: #aab2bf;
                // &.ant-tabs-tab-active .ant-tabs-tab-btn {
                //     font-weight: 600;
                // }
            }
        }
    }
    & > .ant-tabs-content-holder > .ant-tabs-content {
        height: 100%;
    }
}

.tabs-custom-2tab {
    @include tabs-custom-by-number-tab(2);
}
.tabs-custom-3tab {
    @include tabs-custom-by-number-tab(3);
}
.tabs-custom-4tab {
    @include tabs-custom-by-number-tab(4);
}

/** end tabs custom **/

/** dialog **/
.container-dialog-content-custom {
    pointer-events: auto;
    & > * {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
            rgba(0, 0, 0, 0.14) 0px 5px 8px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 14px 0px;
    }
}

.confirm-dialog-yes-no {
    width: 350px;
    height: auto;
    min-height: 150px;
    max-height: 200px;
    border-radius: 10px;
    display: grid;
    grid-template-rows: auto 1fr 45px;
    background: #ffffff;
    user-select: none;
    & > .confirm-dialog-yes-no-title {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        padding: 16px 24px;
        max-width: 100%;
    }
    & > .confirm-dialog-yes-no-content {
        padding: 10px 8px 20px 8px;
        min-height: 50px;
        max-width: 100%;
        text-align: center;
    }
    & > .confirm-dialog-yes-no-footer {
        display: flex;
        width: 100%;
        border-top: 1px solid #f0f0f0;
        & > * {
            height: 100%;
            flex: 1 1 auto;
            font-weight: 600;
            &:not(:last-child) {
                border-right: 1px solid #f0f0f0;
                color: crimson;
            }
        }
    }
}
/** end dialog **/

/** Label Input **/
.label-gray-cutom {
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    color: #aab2bf;
}

/** Menu item **/
.item-context-menu-custom {
    &:hover {
        background-color: #ebecf1;
    }
    &:active {
        color: #fff;
    }
}

/** pdfviewer **/

.fv__ui-pdfviewer {
    overflow: hidden;
    overflow-y: hidden;
    position: relative;
    height: 100%;
}

/** end pdfviewer **/

/** no data **/
.no-data {
    position: absolute;
    inset: 0;
    background-color: white;
    z-index: 10;
    @include flex-center-mixin();
}
.container-no-data {
    position: relative;
    width: 100%;
    height: 100%;
}
/** end no data **/
.tool-tip-overlay {
    z-index: 1350 !important;
}
/** context menu viewer **/
.container-context-menu-viewer {
    & > div:not(:last-child) {
        border-bottom: 1px solid #ebecf1;
    }
    & li.ant-menu-item.ant-menu-item-only-child {
        height: 35px !important;
        line-height: 35px !important;
    }
    & li.ant-menu-submenu.ant-menu-submenu-vertical {
        height: 35px !important;
        margin: 3px auto;
        display: flex;
        align-items: center;
    }
}
.context-tree {
    & li.ant-menu-item.ant-menu-item-only-child {
        height: 35px !important;
        line-height: 35px !important;
    }
    & li.ant-menu-submenu.ant-menu-submenu-vertical {
        height: 35px !important;
        margin: 3px auto;
        display: flex;
        align-items: center;
    }
}
/** Menu item on hover **/
span.ant-menu-title-content:hover {
    background-color: inherit;
}

/** revit title **/
.rvt-title-icon {
    color: #3272ba;
    padding: 0 6px;
    position: relative;
    font-weight: 800;
    margin-left: -2px;
    &::before {
        position: absolute;
        inset: 2px;
        content: "";
        border: 0.5px solid;
        border-radius: 2px;
    }
}
/** end revit icon **/

/** custom input **/
.custom-input-dashboard {
    flex: 1 1 auto;
    height: 40px;
    max-width: 400px;
    border-radius: 4px !important;
    border: unset !important;
    background-color: rgba($color: #aab2bf, $alpha: 0.2) !important;
    & > input {
        background-color: transparent !important;
    }
}
@mixin custom-input-mixin($text-align: start) {
    height: 35px;
    max-width: 400px;
    border-radius: 4px !important;
    border: unset !important;
    background-color: rgba($color: #aab2bf, $alpha: 0.2) !important;
    & > input {
        background-color: transparent !important;
        text-align: $text-align;
    }
}
.custom-input {
    @include custom-input-mixin();
}
.custom-input-text-center {
    @include custom-input-mixin(center);
}
/** end custom input **/

.container-hidden-display {
    & .target-hidden-display {
        display: none;
    }
    &:hover .target-hidden-display {
        display: block;
    }
}
.overflow-x-hidden-important {
    overflow-x: hidden !important;
}
/** radio custom **/
.custom-radio {
    display: inline-flex !important;
    align-items: center;
    & > .ant-radio .ant-radio-inner {
        width: 28px;
        height: 28px;
        &::after {
            width: 26px;
            height: 26px;
            top: 8px;
            left: 8px;
            // border-radius: 18px;
        }
    }
}
/** end radio custom **/
/** dashboard **/
.content-dashboard {
    min-width: 900px;
    width: 1028px;
    max-width: 1028px;
    height: 550px;
    min-height: 500px;
    max-height: 550px;
}
/** end dasdboard **/
/** model-file **/
.model-file-custom {
    border: 0.8px solid rgba(170, 178, 191, 0.1);
    grid-template-rows: auto 1fr;
}
/** End model-file **/

.pdf-textbox-temp {
    position: absolute;
    inset: 0;
    pointer-events: auto;
    z-index: 300;
    background: transparent;
    cursor: crosshair;
}

// Quill custom style
div.ql-editor {
    @include scroll-custom();
}

.ql-snow .ql-picker.ql-font {
    .ql-picker-label[data-value="arial"]::before {
        font-family: "arial";
        content: "Arial";
    }
    .ql-picker-label[data-value="couriernew"]::before {
        font-family: "couriernew";
        content: "Courier New";
    }
    .ql-picker-label[data-value="palatino"]::before {
        font-family: "palatino";
        content: "Palatino";
    }
    .ql-picker-label[data-value="timesnewroman"]::before {
        font-family: "timesnewroman";
        content: "Times New Roman";
    }
    .ql-picker-label[data-value="trebuchet"]::before {
        font-family: "trebuchet";
        content: "Trebuchet";
    }
    .ql-picker-label[data-value="verdana"]::before {
        font-family: "verdana";
        content: "Verdana";
    }
    .ql-picker-label::before {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 95px;
    }
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before {
    content: attr(data-value);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
    content: attr(data-value);
    font-size: 14px;
    line-height: 14px;
}
.ql-snow .ql-picker.ql-font {
    .ql-picker-item[data-value="arial"]::before {
        font-family: "arial";
        content: "Arial";
    }
    .ql-picker-item[data-value="couriernew"]::before {
        font-family: "couriernew";
        content: "Courier New";
    }
    .ql-picker-item[data-value="palatino"]::before {
        font-family: "palatino";
        content: "Palatino";
    }
    .ql-picker-item[data-value="timesnewroman"]::before {
        font-family: "timesnewroman";
        content: "Times New Roman";
    }
    .ql-picker-item[data-value="trebuchet"]::before {
        font-family: "trebuchet";
        content: "Trebuchet";
    }
    .ql-picker-item[data-value="verdana"]::before {
        font-family: "verdana";
        content: "Verdana";
    }
    font-size: 14px;
    line-height: 14px;
}
.ql-picker-options {
    max-height: 150px;
    overflow: auto;
    @include scroll-custom();
}

.ql-font-arial {
    font-family: "arial";
}
.ql-font-couriernew {
    font-family: "couriernew";
}
.ql-font-palatino {
    font-family: "palatino";
}
.ql-font-timesnewroman {
    font-family: "timesnewroman";
}
.ql-font-trebuchet {
    font-family: "trebuchet";
}
.ql-font-verdana {
    font-family: "verdana";
}

.ql-snow .ql-picker.ql-size {
    width: 60px !important;
}
.ql-size.ql-picker.ql-expanded
    > .ql-toolbar.ql-snow
    .ql-picker.ql-expanded
    .ql-picker-options {
    width: 60px !important;
}
.ql-color.ql-picker.ql-color-picker.ql-expanded > .ql-picker-options {
    width: 120px !important;
}
.ql-font.ql-picker > .ql-picker-label {
    width: 120px !important;
}
.ql-editor p {
    font-size: 16px;
}

// end Quill style

.no-scroll {
    overflow-y: hidden !important;
}
.auto-scroll {
    overflow-y: auto !important;
}

// start custom dynamic css
@mixin height-custom-percent($percent) {
    height: $percent + "%";
}
$max-percent-value: 20;
@mixin height-custom {
    @for $i from 1 through $max-percent-value {
        .h-#{$i * 5}p {
            @include height-custom-percent(#{$i * 5});
        }
    }
}
@include height-custom;

@mixin width-custom-percent($percent) {
    width: $percent + "%";
}
@mixin width-custom {
    @for $i from 1 through $max-percent-value {
        .w-#{$i * 5}p {
            @include width-custom-percent(#{$i * 5});
        }
    }
}
@include width-custom;

// end custom dynamic css

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light.ant-menu-submenu-placement-rightTop {
    z-index: 1400;
}
.custom-context-menu {
    // ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
    //     height: 45px;
    // }
    .ant-menu-vertical.ant-menu-item {
        line-height: 32px !important;
    }
}
.submenu-context {
    width: 100px !important;
    .ant-menu.ant-menu-sub.ant-menu-vertical {
        min-width: auto !important;
    }
}
.ant-menu-title-content {
    display: flex;
    &:hover {
        background-color: #bebebe;
    }
}
.tab-info-custom {
    .ant-tabs-nav-list {
        padding-left: 20px;
    }
}
.h-inherit {
    height: inherit !important;
}
.rotation-cursor {
    cursor: url(./images/rotate.png), default;
}
// .rotation-image {
// background-image: url(./images/rotate.png);
// }
.rotation-image::after {
    content: "";
    height: 17px;
    border-left: 1px solid #5aa;
    position: absolute;
    top: -18px;
    left: 50%;
    pointer-events: none;
}
.popup-slider {
    .ant-slider {
        padding: 0px !important;
    }
}
.ant-popover-inner-content {
    padding: 0px !important;
}
.popup-linecolor {
    .flexbox-fix:nth-child(3) {
        display: none !important;
    }
    box-shadow: none !important;
}
.rc-virtual-list-holder {
    @include scroll-custom();
}
.transform-rotate {
    & svg {
        transform: rotateY(180deg);
    }
}
.display-block {
    display: block !important;
}
.display-none {
    display: none !important;
}
.ant-tooltip-inner {
    pointer-events: none;
}
.textbox-placeholder::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--placeholder-color);
    opacity: 1; /* Firefox */
}
.textbox-placeholder:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--placeholder-color);
}
.textbox-placeholder::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--placeholder-color);
}
.editable-placeholder:empty:before {
    content: attr(data-placeholder);
}
.k-toolbar {
    padding: 0 !important;
}
.modal-pointer-event {
    .ant-modal-mask {
        background: transparent;
        pointer-events: none;
    }
    .ant-modal-wrap {
        pointer-events: none;
    }
    .ant-modal {
        pointer-events: all;
    }
}
.bdbox-wrap .tooltip-pinmarker {
    opacity: 0;
    transition: opacity 0.1s ease-out;
}

.bdbox-wrap:hover .tooltip-pinmarker {
    opacity: 1;
}

.custom-antd-scrollbar {
    .ant-tree-list-scrollbar {
        width: $width-scroll !important;
        box-shadow: inset 0 0 $border-radius-scroll $bg-color-scroll-2 !important;
        border-radius: $border-radius-scroll !important;
        .ant-tree-list-scrollbar-thumb {
            background: $bg-blue-scroll !important;
        }
    }
    .ant-tree-list-scrollbar.ant-tree-list-scrollbar-show {
        background: $bg-color-scoll-track !important;
    }
}
.custom-antd-dropdow-scrollbar {
    .rc-virtual-list-scrollbar {
        width: $width-scroll !important;
        box-shadow: inset 0 0 $border-radius-scroll $bg-color-scroll-2 !important;
        border-radius: $border-radius-scroll !important;
        .rc-virtual-list-scrollbar-thumb {
            background: $bg-blue-scroll !important;
        }
    }
    .rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-show {
        background: $bg-color-scoll-track !important;
    }
}

$light-blue-2: rgb(218, 233, 245); //rgba(166, 194, 249, 0.4);
$light: rgba(166, 194, 249, 0);

.tree-title-hover {
    .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal {
        transition: none !important;
    }
    .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-open {
        transition: none !important;
    }
    .ant-tree-treenode.ant-tree-treenode-switcher-open:hover {
        background: $light-blue-2 !important;
        .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal {
            background: $light !important;
        }
        .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-open {
            background: $light !important;
        }
    }
    .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-close {
        transition: none !important;
    }
    .ant-tree-treenode.ant-tree-treenode-switcher-close:hover {
        background: $light-blue-2 !important;
        .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal {
            background: $light !important;
        }
        .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-close {
            background: $light !important;
        }
    }
    .ant-tree-treenode:hover {
        background: $light-blue-2 !important;
    }
    .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal:hover {
        background: $light-blue-2 !important;
    }
}
.cursor-col-resize {
    cursor: col-resize;
}
.cursor-row-resize {
    cursor: row-resize;
}
.k-animation-container {
    z-index: 2000;
}
.tree-item-title {
    cursor: auto;
}

.bg-yellow-custom {
    background: yellow;
}
.bg-orange-custom {
    background: orange;
}
.bg-trans {
    background: transparent !important;
}
.custom-ant-collapse {
    .ant-collapse-content .ant-collapse-content-box {
        padding: 0;
        .ant-collapse-header {
            background-color: gray;
        }
    }
}
.custom-ant-collapse-header {
    .ant-collapse &.ant-collapse-item >.ant-collapse-header {
        color: black !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        > .grow {
            flex-grow: 1
        }
        font-weight: 400;
    };
    .ant-collapse-content .ant-collapse-content-box {
        padding: 0;
    }
    & .custom-ant-collapse-family-header {
        font-weight: 100;
    }
    & .custom-ant-collapse-type-header {
        background-color: #ccc;
    }
}
.custom-ant-collapse-category-header {
    > .ant-collapse-header{
        border: none;
        padding-top: 1rem;
    }
    .ant-collapse .ant-collapse-item {
        border: none;
    }
    .ant-collapse-content {
        border: none;
    }
    .ant-collapse {
        border: none;
    }
    .ant-collapse-borderless &.ant-collapse-item {
        border: 1px solid #d9d9d9;
    }
}
.site-collapse-custom-collapse{
    background-color: white !important;
} .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 12px;
  border: 1px;
  border-radius: 2px;
}